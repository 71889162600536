/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AllUserGroupsResponse } from '../models/all-user-groups-response';
import { ChangeRoleOfGroupRequest } from '../models/change-role-of-group-request';
import { CountByRoleResponse } from '../models/count-by-role-response';
import { CreateUserGroupRequest } from '../models/create-user-group-request';
import { LinkUsersToGroupRequest } from '../models/link-users-to-group-request';
import { SuccessChangeRoleOfGroupResponse } from '../models/success-change-role-of-group-response';
import { SuccessCreateUserGroupResponse } from '../models/success-create-user-group-response';
import { SuccessDeleteUserGroupResponse } from '../models/success-delete-user-group-response';
import { SuccessLinkUsersToGroupResponse } from '../models/success-link-users-to-group-response';
import { SuccessUnlinkUserFromGroupResponse } from '../models/success-unlink-user-from-group-response';
import { SuccessUpdateUserGroupResponse } from '../models/success-update-user-group-response';
import { SuccessVersionsResponse } from '../models/success-versions-response';
import { UnLinkUsersFromGroupRequest } from '../models/un-link-users-from-group-request';
import { UpdateUserGroupRequest } from '../models/update-user-group-request';
import { UserGroupDetailsResponse } from '../models/user-group-details-response';

@Injectable({
  providedIn: 'root',
})
export class UserGroupsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserGroups
   */
  static readonly GetUserGroupsPath = '/api/v1';

  /**
   * List all user groups within the current organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AllUserGroupsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.GetUserGroupsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AllUserGroupsResponse>;
      })
    );
  }

  /**
   * List all user groups within the current organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroups(params?: {
    context?: HttpContext
  }
): Observable<AllUserGroupsResponse> {

    return this.getUserGroups$Response(params).pipe(
      map((r: StrictHttpResponse<AllUserGroupsResponse>) => r.body as AllUserGroupsResponse)
    );
  }

  /**
   * Path part for operation createUserGroup
   */
  static readonly CreateUserGroupPath = '/api/v1';

  /**
   * Create a new user group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserGroup$Response(params?: {
    context?: HttpContext
    body?: CreateUserGroupRequest
  }
): Observable<StrictHttpResponse<SuccessCreateUserGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.CreateUserGroupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessCreateUserGroupResponse>;
      })
    );
  }

  /**
   * Create a new user group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserGroup(params?: {
    context?: HttpContext
    body?: CreateUserGroupRequest
  }
): Observable<SuccessCreateUserGroupResponse> {

    return this.createUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<SuccessCreateUserGroupResponse>) => r.body as SuccessCreateUserGroupResponse)
    );
  }

  /**
   * Path part for operation getUserGroupById
   */
  static readonly GetUserGroupByIdPath = '/api/v1/{groupId}';

  /**
   * Get details of a specific user group and the users added to it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroupById$Response(params: {
    groupId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserGroupDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.GetUserGroupByIdPath, 'get');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserGroupDetailsResponse>;
      })
    );
  }

  /**
   * Get details of a specific user group and the users added to it.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserGroupById(params: {
    groupId: string;
    context?: HttpContext
  }
): Observable<UserGroupDetailsResponse> {

    return this.getUserGroupById$Response(params).pipe(
      map((r: StrictHttpResponse<UserGroupDetailsResponse>) => r.body as UserGroupDetailsResponse)
    );
  }

  /**
   * Path part for operation deleteUserGroup
   */
  static readonly DeleteUserGroupPath = '/api/v1/{groupId}';

  /**
   * Delete a user group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserGroup$Response(params: {
    groupId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SuccessDeleteUserGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.DeleteUserGroupPath, 'delete');
    if (params) {
      rb.path('groupId', params.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessDeleteUserGroupResponse>;
      })
    );
  }

  /**
   * Delete a user group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserGroup(params: {
    groupId: string;
    context?: HttpContext
  }
): Observable<SuccessDeleteUserGroupResponse> {

    return this.deleteUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<SuccessDeleteUserGroupResponse>) => r.body as SuccessDeleteUserGroupResponse)
    );
  }

  /**
   * Path part for operation updateUserGroupDetails
   */
  static readonly UpdateUserGroupDetailsPath = '/api/v1/{groupId}';

  /**
   * Update user group details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserGroupDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserGroupDetails$Response(params: {
    groupId: string;
    context?: HttpContext
    body?: UpdateUserGroupRequest
  }
): Observable<StrictHttpResponse<SuccessUpdateUserGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.UpdateUserGroupDetailsPath, 'patch');
    if (params) {
      rb.path('groupId', params.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessUpdateUserGroupResponse>;
      })
    );
  }

  /**
   * Update user group details.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserGroupDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserGroupDetails(params: {
    groupId: string;
    context?: HttpContext
    body?: UpdateUserGroupRequest
  }
): Observable<SuccessUpdateUserGroupResponse> {

    return this.updateUserGroupDetails$Response(params).pipe(
      map((r: StrictHttpResponse<SuccessUpdateUserGroupResponse>) => r.body as SuccessUpdateUserGroupResponse)
    );
  }

  /**
   * Path part for operation addUsersToUserGroup
   */
  static readonly AddUsersToUserGroupPath = '/api/v1/{groupId}/users';

  /**
   * Add multiple users to a user group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUsersToUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUsersToUserGroup$Response(params: {
    groupId: string;
    context?: HttpContext
    body?: LinkUsersToGroupRequest
  }
): Observable<StrictHttpResponse<SuccessLinkUsersToGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.AddUsersToUserGroupPath, 'post');
    if (params) {
      rb.path('groupId', params.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessLinkUsersToGroupResponse>;
      })
    );
  }

  /**
   * Add multiple users to a user group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUsersToUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUsersToUserGroup(params: {
    groupId: string;
    context?: HttpContext
    body?: LinkUsersToGroupRequest
  }
): Observable<SuccessLinkUsersToGroupResponse> {

    return this.addUsersToUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<SuccessLinkUsersToGroupResponse>) => r.body as SuccessLinkUsersToGroupResponse)
    );
  }

  /**
   * Path part for operation removeUsersFromUserGroup
   */
  static readonly RemoveUsersFromUserGroupPath = '/api/v1/{groupId}/users/remove';

  /**
   * Remove users from a user group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUsersFromUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeUsersFromUserGroup$Response(params: {
    groupId: string;
    context?: HttpContext
    body?: UnLinkUsersFromGroupRequest
  }
): Observable<StrictHttpResponse<SuccessUnlinkUserFromGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.RemoveUsersFromUserGroupPath, 'post');
    if (params) {
      rb.path('groupId', params.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessUnlinkUserFromGroupResponse>;
      })
    );
  }

  /**
   * Remove users from a user group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeUsersFromUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeUsersFromUserGroup(params: {
    groupId: string;
    context?: HttpContext
    body?: UnLinkUsersFromGroupRequest
  }
): Observable<SuccessUnlinkUserFromGroupResponse> {

    return this.removeUsersFromUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<SuccessUnlinkUserFromGroupResponse>) => r.body as SuccessUnlinkUserFromGroupResponse)
    );
  }

  /**
   * Path part for operation changeRoleOfUserGroup
   */
  static readonly ChangeRoleOfUserGroupPath = '/api/v1/{groupId}/role';

  /**
   * Change role of a user group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeRoleOfUserGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRoleOfUserGroup$Response(params: {
    groupId: string;
    context?: HttpContext
    body?: ChangeRoleOfGroupRequest
  }
): Observable<StrictHttpResponse<SuccessChangeRoleOfGroupResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.ChangeRoleOfUserGroupPath, 'patch');
    if (params) {
      rb.path('groupId', params.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessChangeRoleOfGroupResponse>;
      })
    );
  }

  /**
   * Change role of a user group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeRoleOfUserGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRoleOfUserGroup(params: {
    groupId: string;
    context?: HttpContext
    body?: ChangeRoleOfGroupRequest
  }
): Observable<SuccessChangeRoleOfGroupResponse> {

    return this.changeRoleOfUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<SuccessChangeRoleOfGroupResponse>) => r.body as SuccessChangeRoleOfGroupResponse)
    );
  }

  /**
   * Path part for operation getCountOfUserGroupsByRole
   */
  static readonly GetCountOfUserGroupsByRolePath = '/api/v1/count-by-role';

  /**
   * Get count of user groups per role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountOfUserGroupsByRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountOfUserGroupsByRole$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CountByRoleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.GetCountOfUserGroupsByRolePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CountByRoleResponse>;
      })
    );
  }

  /**
   * Get count of user groups per role.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCountOfUserGroupsByRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountOfUserGroupsByRole(params?: {
    context?: HttpContext
  }
): Observable<CountByRoleResponse> {

    return this.getCountOfUserGroupsByRole$Response(params).pipe(
      map((r: StrictHttpResponse<CountByRoleResponse>) => r.body as CountByRoleResponse)
    );
  }

  /**
   * Path part for operation getVersionOfUserGroup
   */
  static readonly GetVersionOfUserGroupPath = '/api/v1/version';

  /**
   * Get version of the microservice or the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersionOfUserGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionOfUserGroup$Response(params?: {

    /**
     * &#x27;user-groups&#x27; for user-groups microservice version and &#x27;organizationalportal&#x27; for rbac version.
     */
    appName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SuccessVersionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserGroupsApiService.GetVersionOfUserGroupPath, 'get');
    if (params) {
      rb.query('appName', params.appName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessVersionsResponse>;
      })
    );
  }

  /**
   * Get version of the microservice or the application.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersionOfUserGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionOfUserGroup(params?: {

    /**
     * &#x27;user-groups&#x27; for user-groups microservice version and &#x27;organizationalportal&#x27; for rbac version.
     */
    appName?: string;
    context?: HttpContext
  }
): Observable<SuccessVersionsResponse> {

    return this.getVersionOfUserGroup$Response(params).pipe(
      map((r: StrictHttpResponse<SuccessVersionsResponse>) => r.body as SuccessVersionsResponse)
    );
  }

}
